import { DndContext } from '@dnd-kit/core';
import { Box, Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useDragPosition } from './useDragPosition';

const DEFAULT_SIZE = 300;
const MAX_SIZE = 600;

const Pane = ({
  children,
  storageKey,
}: {
  children: ReactNode;
  storageKey?: string;
}) => {
  const { position, setNodeRef, attributes, listeners, isDragging } =
    useDragPosition({
      storageKey,
      initial: DEFAULT_SIZE,
      min: DEFAULT_SIZE,
      max: MAX_SIZE,
    });

  return (
    <Stack
      style={{ width: position, flex: '0 0 auto' }}
      height={1}
      position="relative"
      direction="row"
    >
      {children}
      <Box
        sx={{
          '&:hover div': { opacity: 1 },
          position: 'absolute',
          right: -10,
          zIndex: 1000,
        }}
        height={1}
        ref={setNodeRef}
        {...listeners}
        {...attributes}
      >
        <Box
          width={10}
          height={1}
          sx={{
            borderLeft: t => `3px solid ${t.palette.background.default}`,
            borderRight: t => `3px solid ${t.palette.background.default}`,
            opacity: isDragging ? 1 : 0,
            backgroundColor: t =>
              isDragging ? t.palette.primary.main : t.palette.action.hover,
            cursor: 'col-resize',
          }}
        />
      </Box>
    </Stack>
  );
};

export const ResizablePane = ({
  children,
  storageKey,
}: {
  children: ReactNode;
  storageKey?: string;
}) => (
  <DndContext
    onDragEnd={({ active }) => {
      if (storageKey && active.data.current?.position)
        localStorage.setItem(storageKey, String(active.data.current.position));
    }}
  >
    <Pane storageKey={storageKey}>{children}</Pane>
  </DndContext>
);
